import React from 'react';
import { Container, Row, Col } from 'emotion-flex';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import { FiArrowLeft } from 'react-icons/fi';
import { SEO } from '../../../components';
import { withLayout } from '../../../components/Layout';
import { ContentfulVideoPage, File } from '../../../graphql-types';

interface Props {
  pageContext: {
    locale: string;
  };
  data: {
    page: ContentfulVideoPage;
    image1: File;
    image2: File;
    image3: File;
  };
}

const MitmachenPage: React.FC<Props> = ({
  pageContext: { locale },
  data: { page, image1, image2, image3 },
}) => {
  return (
    <div
      css={{
        marginTop: 36,
      }}
    >
      <SEO
        lang={locale}
        title={page.seoTitle}
        description={page.seoDescription}
      />
      <Container>
        <Row>
          <Col xs={12} lgOffset={2} lg={8}>
            <h2>Mitmachen</h2>
            <p>
              Wenn Du uns auf unserer Mission unterstützen möchtest, positive
              Bauchgeschichten zu schreiben, dann brich das Tabu und sprich über
              Deinen Magen und Darm. Mitmachen ist ganz einfach:
            </p>
          </Col>
        </Row>
      </Container>
      <section
        css={{ backgroundColor: '#F4F7F8', paddingTop: 48, paddingBottom: 24 }}
      >
        <Container>
          <Row>
            <Col xs={12} lgOffset={2} lg={8}>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <div css={{ textAlign: 'center' }}>
                    <Image fixed={image1.childImageSharp.fixed} />
                  </div>
                  <h3>Erzähle deine Geschichte</h3>
                  <p>
                    Beantworte diese drei Fragen in 1-2 Minuten und nimm Dich
                    dabei - hochkant - mit dem Handy auf:
                  </p>
                  <ul>
                    <li>Wie heißt Du?</li>
                    <li>
                      Welche chronischen Symptome oder Beschwerden hast Du?
                    </li>
                    <li>Wie gehst Du damit um?</li>
                  </ul>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div css={{ textAlign: 'center' }}>
                    <Image fixed={image2.childImageSharp.fixed} />
                  </div>
                  <h3>Brich das Tabu</h3>
                  <p>
                    Schicke Dein Video und Deinen Social Media Namen an{' '}
                    <a href="mailto:anika@cara.care">anika@cara.care</a>. Wir
                    teilen Deinen Beitrag auf unseren Kanälen und verlinken
                    Dich. Natürlich respektieren wir Deine Privatsphäre! Wenn Du
                    unter einem Pseudonym berichten, nur mit Vornamen genannt
                    oder nicht verlinkt werden möchtest, gib uns einfach
                    Bescheid.
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <div css={{ textAlign: 'center' }}>
                    <Image fixed={image3.childImageSharp.fixed} />
                  </div>
                  <h3>Tausch Dich mit anderen aus</h3>
                  <p>
                    Unter folgenden Hashtags findest Du viele andere
                    Geschichten, die Deiner vielleicht ähnlich sind: #caracares
                    #icare #höraufdeinenbauch Tausch Dich aus, fühl Dich
                    verstanden und gib Deine Erfahrung weiter.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={{ marginTop: 32 }}>
        <Container>
          <Row>
            <Col xs={12} lgOffset={2} lg={8}>
              <p css={{ fontSize: '1rem', color: '#505b7d', marginBottom: 0 }}>
                {page.socialBelowSection}
              </p>
              <div css={{ marginTop: 24 }}>
                <Link
                  to="/de/we_care/"
                  css={{ display: 'flex', alignItems: 'center' }}
                >
                  <FiArrowLeft css={{ marginRight: 8 }} /> Zurück
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export const pageQuery = graphql`
  query pageQuery($locale: String!) {
    page: contentfulVideoPage(node_locale: { eq: $locale }) {
      seoTitle
      seoDescription
      socialSection {
        childMarkdownRemark {
          html
        }
      }
      socialBelowSection
    }
    image1: file(relativePath: { eq: "we_care/icon-video@2x.png" }) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "we_care/icon-message@2x.png" }) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image3: file(relativePath: { eq: "we_care/icon-rocket@2x.png" }) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default withLayout(MitmachenPage, { isNewLayout: true });
